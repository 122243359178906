@import 'import';
$root: '.AdvancedSelection';

#{$root} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  &__text {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: rem(24);
    color: $black;
    letter-spacing: 0;

    b {
      font-weight: bold;
      color: $red;
    }

    @media (max-width: $container-md-max-width) {
      font-size: 17px;
      line-height: 1.5;
    }

    @media (max-width: $container-sm-max-width) {
      @include sr-only();
    }
  }

  &__action {
    display: flex;
    column-gap: 8px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .sortDropdown {
      z-index: 5;
      flex: 1;
    }
  }
}
